import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevRaidBellows: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Bellows of the Earth</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_bellows.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Bellows of the Earth</h1>
          <h2>
            Everything you need to know about the Bellows of the Earth Raid!
          </h2>
          <p>
            Last updated: <strong>08/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <p>
        A returning boss from the Farewell, Rayashki (1.8) event, the Bellows of
        the Earth storms into Mane’s Bulletin as the best (and one of the
        easiest) raid for any character that can deal consistent Mass attacks.
        Get your Lucy’s charged or your J’s (when he releases) sharpened!
      </p>
      <p>There are a total of 2 types of enemies in this fight:</p>
      <ul>
        <li>T“Fury” will be the main boss. </li>
        <li>He will be flanked by 3 “Inhabitants”</li>
      </ul>
      <SectionHeader title="Field Hazards [Endless Mode]" />
      <ul>
        <li>
          [All] Whenever anyone is attacked, reduce their DMG Reduction by 5%
          for that turn (stacks up to 5 times).
        </li>
        <li>
          [Ally] For each enemy defeated, allies gain +1% DMG Bonus (stacks up
          to 20 times). When this reaches 10 stacks, also gain +10% Pen Rate.
        </li>
        <li>
          [Ally] When allies cast Mass attacks, increase its DMG Bonus by 30%
          and Leech Rate by 10%.
        </li>
      </ul>
      <SectionHeader title="Boss Mechanics [Endless Mode]" />
      <h5>Bellows</h5>
      <ul>
        <li>
          <strong>Crustal Vibrations</strong> - At the end of the round, gain 2
          layers of [Rancor], remove 1 layer when attacked; for each friendly
          present, Pen Rate +10%; for each friendly defeated, trigger 1 [Energy
          Explosion].
        </li>
        <ul>
          <li>→ [Rancor]: DMG Bonus +5% (stacks up to 10 times).</li>
          <li>
            → [Energy Explosion]: Mass attack. Deals 50% Reality DMG to all
            allies. In addition, deals damage equal to 10% of Max HP.
          </li>
        </ul>
        <li>
          <strong>Leyline Coexistence</strong> -At the end of the round, if the
          number of units in the party is below 4, summon up to 4 “Inhabitants”.
          For every “Inhabitant” summoned,
        </li>
        <li>
          <strong>Piling Strata</strong> - When attacked, gain 1 stack of
          [Buildup]; gain 2 additional layers if it’s an active action. Upon
          gaining 9 stacks of [Buildup], trigger 1 [Energy Explosion].
        </li>
        <li>
          <strong>[Ultimate] Card</strong> - 1-target attack. Deals 350% Reality
          DMG to the highest ATK enemy. Deals additional Genesis DMG equal to 6%
          of the caster’s current HP.
        </li>
        <li>
          <strong>[Attack] Card</strong> - 1-target attack. Deals 200% Reality
          DMG with the highest HP. Gains 2 stacks of [Sturdiness].
        </li>
        <li>
          <strong>[Attack] Card</strong> - Mass attack. Deals 150% Reality DMG
          to 2 enemies. Deals additional Genesis DMG equal to 2% of the caster’s
          HP.
        </li>
      </ul>
      <h5>Inhabitant</h5>
      <ul>
        <li>
          <strong>Return to the Hotbed</strong> - When defeated, grant all
          allies 2 layers of [Buildup].
        </li>
        <li>
          <strong>Stratum Silt </strong> - When attacked, gain 1 stack of
          [Buildup]; gain 2 additional layers if it’s an active action. Upon
          gaining 9 stacks of [Buildup], trigger 1 [Energy Explosion].
        </li>
        <li>
          <strong>[Attack] Card</strong> - 1-target attack. Deals 150% Reality
          DMG to the enemy with the highest HP. Deals additional Genesis DMG
          equal to 8% of the target’s current HP.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        Mass DMG carries really pop off in this fight, especially ones with
        loads of active incantations. Consider slotting in characters like Lucy,
        J, 37, Isolde and Marcus. There’s really no use in bringing
        single-target monsters here, [Energy Explosion] deals a ridiculous
        amount of boss damage on its own.
      </p>
      <div className="team-box">
        <h5 className="beast">ALTERNATIVE SCIENTOLOGY</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="lucy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="marcus"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="mercuria"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="isolde"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="j"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kakania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="vila"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team centered around providing optimal conditions for either Lucy
            or Marcus. This team focuses on DMG Bonus and Reality DMG, utilizing
            Mercuria and Vila’s combined buffing capabilities to ensure that
            Lucy is always killing things and thus triggering her extra FUA.
          </p>
          <p>
            The set-up here is pretty simple. Let the other three do their thing
            and spam with Lucy. Mercuria is providing DMG Bonus, C.DMG and
            Incantation Might on top of Kakania’s ATK boost and Vila’s
            additional C.Rate/DMG. The same set-up provides optimal conditions
            for characters like Marcus.
          </p>
          <p>
            Other options for Supports include Isolde or J if you need to shred
            Reality DEF or need the extra Mass FUAs to deal with the boss
            mechanics. 37 also functions well as a Mass attack spammer with a
            Mass FUA under her belt.
          </p>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">YOU BURN ME RIGHT ‘ROUND</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="j"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="isolde"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="mercuria"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry/Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kakania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team oriented around spamming as much Mass DMG as possible to fuel
            the boss’ generation of [Buildup] stacks and thus spam [Energy
            Explosion] procs. It mainly abuses the high amounts of damage that
            [Energy Explosion] does against the boss rather than having the team
            deal a load of damage on their own.
          </p>
          <p>
            Like the last, this is a very simple set-up. Isolde and 37 can AFK
            for the most part, with a majority of the onus being put on J to
            upkeep his shields and DMG Bonus buff. Isolde helps with this,
            generating [Preignition] and providing a lot of Burn while her and
            J’s combined FUA count quickly fuels 37 to spam her own FUA.
          </p>
          <p>
            Note that this team is quite risky, as it does not use a proper
            healer, and instead uses Kakania to upkeep the team HP a little
            while the stage hazard’s Leech Rate does the rest.
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevRaidBellows;

export const Head: React.FC = () => (
  <Seo
    title="Bellows of the Earth | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Bellows of the Earth Raid!"
    game="reverse"
  />
);
